.receipt {
    display: none;
    padding: 0 3px 8px 3px;
}
  
@media print {
    .receipt {
        font-family: Arial, sans-serif !important;
        padding: 0;
        margin: 0;
        font-size: 10px;
    }
        
    /* Hide unnecessary elements */
    .hide-on-print {
        display: none;
    }

    .visible-on-print {
        display: block;
    }

    @page { size: auto;  margin: 0mm; }
}