.bold { font-weight: bold !important; }

.medium { font-weight: 500 !important; }

.regular { font-weight: 400 !important; }

.small { font-size: 11px !important; }

.small-x { font-size: 10px !important; }

.font-12 { font-size: 12px !important; }
.font-13 { font-size: 13px !important; }
.font-14 { font-size: 14px !important; }
.font-16 { font-size: 16px !important; }
.font-18 { font-size: 18px !important; }
.font-20 { font-size: 20px !important; }
.font-22 { font-size: 22px !important; }
.font-24 { font-size: 24px !important; }
.font-26 { font-size: 26px !important; }
