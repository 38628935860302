.json-container pre {
    background-color: #192c3d; /* Цвет фона */
    padding: 10px;
    border-radius: 4px;
    overflow: auto; /* Прокрутка, если нужно */
    white-space: pre-wrap; /* Обертывание строк */
    font-family: monospace; /* Шрифт для улучшения читаемости */
}
      
.json-key {
    color: rgba(255, 120, 131, 0.87); /* Подсветка ключей */
    font-weight: bold;
}
      
.json-string {
    color: #f0e37f; /* Подсветка строк */
}
      
.json-number {
    color: #def0ff; /* Подсветка чисел */
}
      
.json-boolean {
    color: rgb(254, 133, 244); /* Подсветка логических значений */
}
      
.json-null {
    color: rgb(90, 162, 246); /* Подсветка null */
}
      
.json-mark {
    color: rgb(210, 210, 211); /* Подсветка ключей */
    font-weight: bold;
}