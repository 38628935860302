.cp-bg {
    background-color: #dee5ee;
}

.cp-body {
    display: flex;
    flex-direction: column;
    background-color: #dee5ee;
    height: 100vh;
    font-family: 'system-ui' !important;
  }

.cp-main-page {
    display: flex;
    margin: 0 16px 20px 16px;
    padding: 16px;
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.cp-home {
    display: flex;
    flex-direction: column;
    margin-right: 230px;
}

.cp-open-sales {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 230px;
    height: 100%;
    right: 0;
    border-left: #dee5ee 1px solid;
    background-color: #ffffff;
    overflow-y: auto;
}

.cp-body-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 6px;
    border-radius: 2px;
    border: rgb(200, 210, 214) 1px solid;
}

.cp-sale-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: #dee5ee 1px solid;
    background-color: rgb(249, 251, 252);
    padding: 5px 10px;
}

.btn-card-8-col {
    height: 120px;
    padding: 12px;
    margin: 6px 0;
    border-radius: 8px;
    color: white;
}

.btn-card-8-col:hover {
    opacity: 0.75;
    cursor: pointer;
}
