.top-status-bar {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  height: 40px;
  padding: 7px 15px;
  background-color: rgb(27, 115, 177);
  border-bottom: white 1px solid;
  border-left:  white 1px solid;
  border-right:  white 1px solid;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 900;
}