.cp-side-bar {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 0px;
    margin-right: 16px;
    font-weight: 600;
    list-style: none;
  }
  
  .cp-side-bar .side-item {
    text-decoration: none;
  }
  
  .cp-side-bar .side-link {
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 3px 0px;
    padding: 20px 10px;
    color: #f2f4f6;
    background-color: #34a6df;
    border-radius: 6px;
    transition: all 0.3s;
  }
  
  .cp-side-bar .side-link.collapsed {
    color: #ffffff;
    background-color: rgb(14, 44, 71);
    border: none;
  }
  
  .cp-side-bar .side-link:hover {
    color: #1e2955;
    background-color: rgb(166, 255, 110);
    margin-right: 0px;
  }