.cp-nav-bar {
    display: flex;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 13px;
    font-weight: 600;
    list-style: none;
    z-index: 900;
  }
  
  .cp-nav-bar .nav-item {
    text-decoration: none;
  }
  
  .cp-nav-bar .icon {
    width: 35px;
    height: 35px;
    margin: 5px 0;
    transition: all 0.05s;
  }
  
  .cp-nav-bar .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 3px;
    padding: 8px 5px 10px 5px;
    color: rgb(15, 107, 193);
    background-color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.3s;
  }
  
  .cp-nav-bar .nav-link.collapsed {
    color: #ffffff;
    background: rgb(135, 154, 166);
    padding-bottom: 5px;
    border-radius: 4px;
  }
  
  .cp-nav-bar .nav-link:hover {
    color: #ffe312;
  }
